// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Needful from './needful'; // The Needful
import WelcomePage from './WelcomePage'; // Landing Page

const App = () => {
  return (
    <Router>
      <Routes> {/* Use Routes instead of Switch */}
        <Route exact path="/" element={<WelcomePage />} />
        <Route path="/Needful" element={<Needful />} /> {/* Updated to use element prop */}
        {/* Future application routes will go here */}
      </Routes>
    </Router>
  );
};

export default App;
