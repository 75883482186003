import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './needful.css';

const App = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [displayApiKey, setDisplayApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
    setDisplayApiKey(event.target.value.replace(/./g, '*'));
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = async () => {
    if (!apiKey) {
      alert('An API key is mandatory.');
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const requestBody = {
        messages: [
          {
            role: "system",
            content: "You are a helpful IT assistant, you will always reply in the style of an Indian IT support team member. Reply with amusing anecdotes, but keep the reply short and sweet"
          },
          {
            role: "user",
            content: input
          }
        ],
        model: "gpt-4"
      };

      const result = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        }
      );

      setResponse(result.data.choices[0].message.content);
      setDisplayApiKey(apiKey.replace(/./g, '*'));
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setInput('');
    setResponse('');
  };

  return (
    <div id="app-container">
      <div id="api-key-container">
        <input 
          type="password" 
          value={displayApiKey} 
          onChange={handleApiKeyChange} 
          placeholder="Enter your API key here..." 
          id="api-key-input"
        />
      </div>
      <img src="/logo1.png" alt="TheNeedFul" id="logo" />
      
      <input 
        type="text" 
        value={input} 
        onChange={handleInputChange} 
        placeholder="How may I do the needful?" 
        disabled={isLoading}
        id="search-box"
      />
      <div className="button-container">
        <button onClick={handleSubmit} disabled={isLoading || !input}>Ask</button>
        <button onClick={handleClear} disabled={isLoading} className="clear-button">Reset</button>
        <Link to="/" className="back-button">Home</Link>
      </div>
      
      {isLoading && (
        <div className="loading-indicator">
          <img src="/ZKZg.gif" alt="Loading..." className="spinner" />
        </div>
      )}
      {response && !isLoading && (
        <div id="response-container">{response}</div>
      )}
      <footer>
        <a href="mailto:do@theneedful.co.uk" className="footer-icon">
          <img src="/email.png" alt="Email" className="icon" />
               
        </a>
      </footer>
    </div>
  );
};

export default App;
