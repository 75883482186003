// WelcomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './welcomepage.css'; // Ensure the CSS file is correctly referenced

const WelcomePage = () => {
  return (
    
      <div className="app-blocks">
        <div className="app-block">
          <img src='logo1.png' alt="The Needful" className="app-icon" />
          <h2 className="app-header">The Needful</h2>
          <p className="app-description">A search App utilising the GPT4 API.<br></br>
          Perfect for IT Troubleshooting.
          </p>
          <Link to="/needful"><button>Launch</button></Link>
        </div>

        
        <div className="app-block">
          <img src='schneider.png' alt="mySchneider Knowledge Bot" className="app-icon" />
          <h2 className="app-header">mySchneider Knowledge Bot</h2>
          <p className="app-description">A search App utilising the GPT4 API Assistant. <br></br> this is a concept & WIP.</p>
          <Link to="/myschneider"><button>Launch</button></Link>
        </div>
      </div>
        
  )
}

<footer>
        <a href="mailto:do@theneedful.co.uk" className="footer-icon">
          <img src="/email.png" alt="Email" className="icon" />
        </a>
        <a href="https://chat.openai.com/" className="footer-icon">
          <img src="/gpt-icon.png" alt="GPT" className="icon" />
        </a>
      
      </footer>

    

export default WelcomePage;
